<template>
  <v-sheet>
    <div class="d-flex flex-column flex-sm-row">
      <v-autocomplete
        v-bind="$attrs"
        class="mt-5 mt-sm-0"
        label="Choose a saved search"
        :items="savedParameters"
        v-model="selectedParams"
        item-text="name"
        item-value="id"
        return-object
        clearable
      />
      <div class="d-flex justify-space-around mt-3 mt-sm-0">
        <v-btn
          v-if="vuexName && !selectedParams"
          @click="openSaveDialog"
          class="ml-0 ml-sm-5 my-auto"
        >
          {{ $vuetify.lang.t("$vuetify.base.saveSearch") }}
        </v-btn>

        <v-btn
          v-if="selectedParams"
          @click="loadParams()"
          class="ml-5 ml-sm-5 my-auto"
        >
          {{ $vuetify.lang.t("$vuetify.base.load") }}
        </v-btn>
        <v-menu v-if="selectedParams" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-3 ml-3" v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-2">
            <v-list-item v-if="vuexName" @click="openSaveDialog">
              <v-list-item-content>
                {{
                  selectedParams
                    ? $vuetify.lang.t("$vuetify.base.updateSearch")
                    : $vuetify.lang.t("$vuetify.base.saveSearch")
                }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="remove(selectedParams)">
              <v-list-item-content>
                {{ $vuetify.lang.t("$vuetify.base.delete") }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <v-dialog
      max-width="600px"
      width="80%"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="saveDialog"
    >
      <v-card>
        <v-card-title>
          {{ $vuetify.lang.t("$vuetify.base.saveSearch") }}
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="name" label="Assign a name to this search" />
          <!-- <v-checkbox
                    v-model="ownerOnly"
                    label="Viewable only by me"
                    ></v-checkbox> -->
        </v-card-text>
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn v-if="selectedParams" @click="updateParams(selectedParams)">
            {{ $vuetify.lang.t("$vuetify.base.update") }}
          </v-btn>
          <v-btn v-else @click="saveParams"> {{ $vuetify.lang.t("$vuetify.base.save") }} </v-btn>
          <v-btn @click="saveDialog = false"> {{ $vuetify.lang.t("$vuetify.base.cancel") }} </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmation-dialog ref="confirm" />
  </v-sheet>
</template>

<script>
import { mapActions } from "vuex";
import cloneDeep from "lodash/cloneDeep";

export default {
  props: {
    pageName: {
      type: [String],
      required: true,
      default: null,
    },
    vuexName: {
      type: [String],
      required: false,
      default: null,
    },
    doNotSaveDates: {
      type: Boolean,
      default: true,
    },
    loadOnInit:{
      type: Boolean,
      default: false,
    },
    parameters: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => {
    return {
      savedParameters: [],
      name: null,
      ownerOnly: false,
      saveDialog: false,
      selectedParams: null,
    };
  },
  created() {
    const loadLastSaved = this.loadOnInit;
    this.getResources(loadLastSaved);
  },
  computed: {
    lastPageParamKey() {
      return `last_page_params_${this.pageName}_${this.vuexName}`;
    },
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("pageParameters", [
      "deletePageParameters",
      "fetchPageParameters",
      "createPageParameters",
      "updatePageParameters",
    ]),
    getResources(loadLastSaved) {
      const params = {
        page: 0,
        size: 300,
        pageName: this.pageName,
      };
      if (this.vuexName) {
        params.vuexName = this.vuexName;
      }
      this.fetchPageParameters(params)
        .then((response) => {
          if (response) {
            this.savedParameters = response.content;

            //Automatically load last used params
            if(loadLastSaved){
              const lastLoadedParamsId = localStorage.getItem(
                this.lastPageParamKey
              );
              if (lastLoadedParamsId) {
                this.selectedParams = this.savedParameters.find(
                  (p) => p.id == lastLoadedParamsId
                );
                if (this.selectedParams) {
                  this.loadParams();
                }
              }
            }
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },
    saveParams() {
      const cloneParameters = cloneDeep(this.parameters);
      if(this.doNotSaveDates){
        cloneParameters['startDate'] = null;
        cloneParameters['endDate'] = null;
        cloneParameters['start'] = null;
        cloneParameters['end'] = null;
      }

      const data = {
        name: this.name ? this.name : "No Name",
        page_name: this.pageName,
        vuex_name: this.vuexName,
        owner_only: this.ownerOnly,
        parameters: JSON.stringify(cloneParameters),
      };
      this.createPageParameters(data)
        .then((response) => {
          console.log(response);
          this.addSuccessMessage("Page Parameters was created successfully");
          this.getResources();
          this.saveDialog = false;
        })
        .catch(() => {
          this.addErrorMessage(
            "Error creating PageParameters, please try again later"
          );
        });
    },
    updateParams(params) {
      const data = cloneDeep(params);
      data.parameters = JSON.stringify(this.parameters);
      data.name = this.name ? this.name : "No Name";
      data.owner_only = this.ownerOnly;

      this.updatePageParameters(data)
        .then((response) => {
          console.log(response);
          this.addSuccessMessage("Page Parameters was updated successfully");
          this.saveDialog = false;
          this.selectedParams = null;
          this.getResources();
        })
        .catch(() => {
          this.addErrorMessage(
            "Error updating Page Parameters, please try again later"
          );
        });
    },
    loadParams() {
      const parameters = JSON.parse(this.selectedParams.parameters);

      this.$store.dispatch(`${this.vuexName}/updateParameters`, parameters);
      this.addSuccessMessage(
        `Loaded saved search parameters: ${this.selectedParams.name}`
      );

      localStorage.setItem(this.lastPageParamKey, this.selectedParams.id);
      this.$emit("onLoad", parameters);
      this.selectedParams = null;
    },
    openSaveDialog() {
      this.saveDialog = true;
      if (this.selectedParams) {
        this.name = this.selectedParams.name;
        this.ownerOnly = this.selectedParams.ownerOnly;
      }
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this page Parameters?"
      );
      if (confirm) {
        this.deletePageParameters(item.id)
          .then(() => {
            this.addSuccessMessage("page Parameters deleted");
            this.selectedParams = null;
            this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },
  },
};
</script>

<style></style>
